﻿/// <reference path="variables">
/// <reference path="mixins">

.icon-toggle-collapse {
    @extend .glyphicon;
    @extend .glyphicon-triangle-bottom;
}

//ajout de auto width
//https://github.com/twbs/bootstrap/issues/11346
@media (min-width: $screen-xs-min) {
	.col-xs-auto {
		width: auto;
	}
}

@media (min-width: $screen-sm-min) {
	.col-sm-auto {
		width: auto;
	}
}

@media (min-width: $screen-md-min) {
	.col-md-auto {
		width: auto;
	}
}

@media (min-width: $screen-lg-min) {
	.col-lg-auto {
		width: auto;
	}
}


@media (max-width: $screen-xs-max) 
{
	.width-100pct-xs
	{
		width: 100%;
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) 
{
	.width-100pct-sm 
	{
		width: 100%;
	}
}



